<template>
  <div id="app">
    <div class="bg_green">
      <div class="screen clear">
        <div class="screen_l clear" @click="showPicker = true">
          <div class="screen_l_l">{{ value }}</div>
          <div class="screen_l_r iconfont">&#xeb5a;</div>
        </div>

        <van-popup v-model="showPicker" position="bottom">
          <van-picker
              show-toolbar
              :columns="columns"
              @confirm="onConfirm"
              @cancel="showPicker = false"
          />
        </van-popup>
        <!--<div class="screen_r"><img src="../assets/images/ico_screen_bar.png" alt=""></div>-->
      </div>
      <div class="white_box3">
        <div class="screen_list">
          <ul v-if="transactionOptions && transactionOptions.length>0">
            <li v-for="(option,index) in transactionOptions" :key="index" @click="jumpFlowDetail(option)">
              <span><b style="color: #222222">{{ getTransactionDesc(option) }}</b> {{ option.productBundle ? option.productBundle.name : '' }} {{
                  option.purchase ? option.purchase.code : ''
                }}</span>
              <label v-if="option.purchase && option.type == 'REFUND'" style="color: #A1A1A1">{{
                  option.createdTime |
                      dayjs
                }}</label>
              <label v-if="option.purchase && option.type !== 'REFUND'" style="color: #A1A1A1">{{
                  option.purchase.consumeTime |
                      dayjs
                }}</label>
              <label v-if="!option.purchase">{{ option.createdTime | dayjs }}</label>
              <div v-if="option.businessId==='017b972ae06b003b2c96c1f37b970f88' && option.remark === '储值卡消费'">
                红包消费
              </div>
              <em v-html="getSymbol(option)"></em>
            </li>
          </ul>
          <van-empty v-if="!transactionOptions || transactionOptions.length===0" description="暂无数据"/>
        </div>
      </div>
    </div>

    <div class="loading_all" v-if="loading">
      <van-loading type="spinner" color="#ffffff" vertical>加载中...</van-loading>
    </div>
  </div>
</template>


<style scoped>

</style>

<script>
import Vue from 'vue'
import {ACCESS_TOKEN, ACCESS_CustomerUser} from '@/store/mutation-types'
import {post} from '@/utils/http'
import {Dialog} from 'vant';
import moment from 'moment'

export default {
  data() {
    return {
      value: '',
      columns: [],
      showPicker: false,
      loading: false,
      transactionOptions: []
    };
  },
  mounted() {
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener('popstate', this.goBack, false);
    }
    let now = new Date();
    let d = new Date(moment(now.getTime()).format('YYYY-MM') + '-01')
    this.value = moment(now.getTime()).format('YYYY-MM')
    this.columns = [this.value]
    for (let i = 1; i < 3; i++) {
      d = new Date(d.setMonth(d.getMonth() - 1))
      this.columns.push(moment(d.getTime()).format('YYYY-MM'))
    }
    this.businessCustomerUserId = this.$route.query.businessCustomerUserId;
    this.loadTransaction();
  },
  destroyed() {
    window.removeEventListener('popstate', this.goBack, false);
  },
  methods: {
    goBack() {
      this.loading = false;
      // this.$router.push('/account/detail');
      this.$router.push('/customer_user_account');
      window.history.pushState('forward', null, '');
      window.history.forward(1);
    },
    // 时间差计算
    difference(beginTime, endTime) {
      var dateBegin = new Date(beginTime);
      var dateEnd = new Date(endTime);
      var dateDiff = dateEnd.getTime() - dateBegin.getTime(); //时间差的毫秒数
      var dayDiff = Math.floor(dateDiff / (24 * 3600 * 1000)); //计算出相差天数
      var leave1 = dateDiff % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
      var hours = Math.floor(leave1 / (3600 * 1000)); //计算出小时数
      //计算相差分钟数
      var leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
      var minutes = Math.floor(leave2 / (60 * 1000)); //计算相差分钟数
      //计算相差秒数
      var leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
      var seconds = Math.round(leave3 / 1000);
      return dayDiff * 24 * 60 + hours * 60 + minutes;
    },
    getTransactionDesc(data) {
      var type = data.type;
      // 显示补扣费逻辑，流水的创建时间-消费时间>60分钟
      if (type === 'RECHARGE') {
        return '充值';// 充值
      }
      if (type === 'DEDUCTION') {
        if (data.remark === '储值卡消费') return '红包';
        if (data.purchase) {
          if (this.difference(data.purchase.consumeTime, data.createdTime) > 60) {
            console.log(data.purchase.consumeTime, data.createdTime, this.difference(data.purchase.consumeTime, data.createdTime))
            return '补扣费';
          }
        }
        return '扣费';// 消费 补扣费 红包
      }
      if (type === 'WITHDRAW') {
        return '提现';// 提现
      }
      if (type === 'REFUND') {
        return '退款';// 退费
      }
      if (type === 'PARTIAL_REFUND') {
        return '部分退款';// 退费
      }
      return type;
    },
    loadTransaction() {
      try {
        let val = this.value.replace('-', '/') + '/01'
        val = val.replace(/-/g, '/')
        let start = new Date(val + ' 00:00:00')
        let end = new Date(start.getFullYear(), start.getMonth() + 1, 0)
        end = new Date(moment(end.getTime()).format('YYYY/MM/DD') + ' 23:59:59')
        // alert(moment(start.getTime()).format('YYYY/MM/DD'))
        // alert(moment(end.getTime()).format('YYYY/MM/DD'))

        this.loading = true
        post('api/transaction/log/v2/search/owner', {
          filters: [
            {
              name: 'type',
              op: 'in',
              value: ['RECHARGE', 'DEDUCTION', 'WITHDRAW', 'REFUND', 'PARTIAL_REFUND']
            },
            {name: 'businessCustomerUserId', op: '=', value: this.businessCustomerUserId},
            {name: 'createdTime', op: '>=', value: start.getTime()},
            {name: 'createdTime', op: '<=', value: end.getTime()},
            {name: 'state', op: '=', value: 'COMPLETE'},
          ],
          sort: 'createdTime desc',
          page: 0,
          size: 500
        }).then(res => {
          this.transactionOptions = res.data.rows
          this.loading = false
        }).catch(() => this.loading = false)
      } catch (err) {
        alert(err.message)
      }
    },
    jumpFlowDetail(data) {
      this.$router.push({path: '/flowDetails', query: {id: data.id}})
    },
    onConfirm(value) {
      this.value = value;
      this.showPicker = false;
      this.loadTransaction()
    },
    getSymbol(data) {
      if (data.type === 'RECHARGE') return '<div style="color: #ffd700; font-weight: bold;" >+' + data.amount.toFixed(2) + '</div>';
      if (data.type === 'DEDUCTION') return '<div style="font-weight: bold;" >-' + data.amount.toFixed(2) + '</div>';
      if (data.type === 'WITHDRAW') return '<div style="font-weight: bold;" >-' + data.amount.toFixed(2) + '</div>';
      if (data.type === 'REFUND') return '<div style="color: #ffd700; font-weight: bold;" >+' + data.amount.toFixed(2) + '</div>';
      if (data.type === 'PARTIAL_REFUND') return '<div style="color: #ffd700; font-weight: bold;" >+' + data.amount.toFixed(2) + '</div>';
      return '';
    }
  }
};
</script>
